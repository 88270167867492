import { useCabinetStore } from '~/stores/cabinet';
import { useGlobalsStore } from '~/stores/globals';
import { useAuthStore } from '~/stores/auth';
import { PLATFORM_BY_DEVICE_TYPE } from '~/constants/general';
import { REDIRECTION_ROUTES_QUERIES } from '~/constants/redirectionRoutes';
import { LOCAL_STORAGE } from '~/constants/storedPropertiesNames';
import type { NotificationsItem } from '~/types/components/General/Notifications';

export default defineNuxtRouteMiddleware(async (to) => {
  if (import.meta.server) {
    return;
  }

  const { getUserData } = useAuthStore();
  const { fetchFreeSpinsCount } = useCabinetStore();
  const { setNotification } = useGlobalsStore();

  if (
    getUserData.loggedIn &&
    !to.path.includes('/game/') &&
    !to.fullPath.includes(`?${REDIRECTION_ROUTES_QUERIES.cashier}`)
  ) {
    const notificationsFromStorage = useLocalStorageObject.get(LOCAL_STORAGE.notifications)  as NotificationsItem[];
    const freeSpinItem = await fetchFreeSpinsCount(PLATFORM_BY_DEVICE_TYPE[useDeviceType()]);

    if (freeSpinItem) {
      const hasSameNotification =  notificationsFromStorage?.some((notif) => notif.bonusId === freeSpinItem.bonus_id);

      if (!hasSameNotification) {
        setNotification({
          dictionaryKeyTitle: 'general_free_spins',
          dictionaryKeyText: 'cabinet_balance_promocode_bonus_added_free_spins',
          dictionaryKeyTextProps: {
            count: freeSpinItem.prize,
            name: freeSpinItem.games[0].name,
          },
          isFreeSpins: true,
          bonusId: freeSpinItem.bonus_id,
          buttons: [
            {
              class: 'text-none text-body-1 px-9 px-lg-5 j-btn j-btn--gradient-main',
              dictionaryKey: 'general_play',
              action: {
                name: 'gameLink',
                gamePrettyName: freeSpinItem.games[0].prettyName,
              },
            },
          ],
        });
      }
    }
  }
});
